import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`13th September 2022`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now repeatedly aggregate an `}<a parentName="p" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{` and `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` when using
`}<a parentName="p" {...{
            "href": "type://HttpRequest#aggregate():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html#aggregate()"
          }}>{`type://HttpRequest#aggregate()`}</a>{` and `}<a parentName="p" {...{
            "href": "type://HttpResponse#aggregate():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#aggregate()"
          }}>{`type://HttpResponse#aggregate()`}</a>{`. If an `}<a parentName="p" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{` or an
`}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` is aggregated before, the cached `}<a parentName="p" {...{
            "href": "type://AggregatedHttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/AggregatedHttpRequest.html"
          }}>{`type://AggregatedHttpRequest`}</a>{` or
`}<a parentName="p" {...{
            "href": "type://AggregatedHttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/AggregatedHttpResponse.html"
          }}>{`type://AggregatedHttpResponse`}</a>{` is returned for subsequent calls. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3789"
          }}>{`#3789`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4366"
          }}>{`#4366`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpRequest request = ...;
AggregatedHttpRequest aggregatedReq0 = request.aggregate().join();
AggregatedHttpRequest aggregatedReq1 = request.aggregate().join();
assert aggregatedReq0 == aggregatedReq1;

HttpResponse response = ...;
AggregatedHttpResponse aggregatedRes0 = response.aggregate().join();
AggregatedHttpResponse aggregatedRes1 = response.aggregate().join();
assert aggregatedRes0 == aggregatedRes1;
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize the behavior of auto-refresh for an `}<inlineCode parentName="p">{`AddressResolverGroup`}</inlineCode>{` with
`}<a parentName="p" {...{
            "href": "type://DnsResolverGroupBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/DnsResolverGroupBuilder.html"
          }}>{`type://DnsResolverGroupBuilder`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4400"
          }}>{`#4400`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Disable auto refresh 
ClientFactory
  .builder()
  .domainNameResolverCustomizer(customizer -> { 
    customizer.enableAutoRefresh(false); // 👈👈👈
  })
  .build();

// Set a constant refresh timeout 
ClientFactory
  .builder()
  .domainNameResolverCustomizer(customizer -> { 
    customizer.autoRefreshTimeout(Duration.ofDays(1)); // 👈👈👈
  })
  .build(); 

// Build a flexible refresh timeout depending on the hostname
ClientFactory
  .builder()
  .domainNameResolverCustomizer(customizer -> { 
    customizer.autoRefreshTimeout(hostname -> {  // 👈👈👈
      if (hostname.endsWith("busy.domain.com")) { 
        // Automatically refresh the cached domain for 7 days. 
        return Duration.ofDays(7).toMillis(); 
      } 
      if (hostname.endsWith("sporadic.domain.dom")) { 
        // Don't need to refresh a sporadically used domain. 
        return 0; 
      } 
      ... 
    }); 
  })
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now export `}<a parentName="p" {...{
            "href": "type://RequestOnlyLog#requestCause():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestOnlyLog.html#requestCause()"
          }}>{`type://RequestOnlyLog#requestCause()`}</a>{` and `}<a parentName="p" {...{
            "href": "type://RequestLog#responseCause():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html#responseCause()"
          }}>{`type://RequestLog#responseCause()`}</a>{` to MDC
by specifying `}<inlineCode parentName="p">{`req.cause`}</inlineCode>{` and `}<inlineCode parentName="p">{`res.cause`}</inlineCode>{` to an appender of a Logback configuration.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`<?xml version="1.0" encoding="UTF-8"?>
<configuration>
  <appender name="RCEA" class="com.linecorp.armeria.common.logback.RequestContextExportingAppender">
    ...
    <export>req.cause</export>  <!-- 👈👈👈 -->
    <export>res.cause</export>  <!-- 👈👈👈 -->
    ...
  </appender>
</configuration>
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now trace a `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` leak by specifying `}<inlineCode parentName="p">{`-Dcom.linecorp.armeria.requestContextLeakDetectionSampler=<sampler-spec>`}</inlineCode>{`
or implementing a custom `}<a parentName="p" {...{
            "href": "type://FlagsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html"
          }}>{`type://FlagsProvider`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4100"
          }}>{`#4100`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4232"
          }}>{`#4232`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public final class LeakDetectingFlagsProvider implements FlagsProvider {
  @Override
  public Sampler<? super RequestContext> requestContextLeakDetectionSampler() {
    // Samples all request contexts.
    return Sampler.always();
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` now automatically generates a specification of annotated services for well known types. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4309"
          }}>{`#4309`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4322"
          }}>{`#4322`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Java POJO`}</li>
          <li parentName="ul">{`Kotlin data class`}</li>
          <li parentName="ul">{`Scala case class`}</li>
          <li parentName="ul">{`Protocol Buffers' `}<inlineCode parentName="li">{`Message`}</inlineCode></li>
          <li parentName="ul">{`ScalaPB's `}<inlineCode parentName="li">{`GeneratedMessage`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now upload files to your `}<a parentName="p" {...{
            "href": "type://GraphqlService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlService.html"
          }}>{`type://GraphqlService`}</a>{` using
`}<a parentName="p" {...{
            "href": "https://github.com/jaydenseric/graphql-multipart-request-spec"
          }}>{`GraphQL multipart requests`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3971"
          }}>{`#3971`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4199"
          }}>{`#4199`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<inlineCode parentName="p">{`armeria-version-catalog`}</inlineCode>{` module to easily import Armeria and Armeria's direct dependencies
using `}<a parentName="p" {...{
            "href": "https://docs.gradle.org/current/userguide/platforms.html#sec:version-catalog-plugin"
          }}>{`Gradle's version catalog`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4377"
          }}>{`#4377`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-groovy"
          }}>{`// in settings.gradle
dependencyResolutionManagement {
  versionCatalogs {
    create("armeriaDeps") {
      from("com.linecorp.armeria:armeria-version-catalog:1.19.0")
    }
  }
}
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CompositeException`}</inlineCode>{` now shows full stack traces of all nested exceptions
when `}<inlineCode parentName="li">{`-Dcom.linecorp.armeria.verboseExceptions=true`}</inlineCode>{` is set. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4347"
        }}>{`#4347`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4356"
        }}>{`#4356`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now use Armeria Spring actuator with WebFlux correctly. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4396"
          }}>{`#4396`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4408"
          }}>{`#4408`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You no longer see `}<inlineCode parentName="p">{`ClassCastException`}</inlineCode>{` when an invalid path request is received. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4405"
          }}>{`#4405`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Annotated services now correctly return `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` status when `}<inlineCode parentName="p">{`void`}</inlineCode>{`, `}<inlineCode parentName="p">{`Publisher<Void>`}</inlineCode>{` or
`}<inlineCode parentName="p">{`CompletionStage<Void>`}</inlineCode>{` and `}<inlineCode parentName="p">{`@ProducesJson`}</inlineCode>{` are used at the same time. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4390"
          }}>{`#4390`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4398"
          }}>{`#4398`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// 200 OK status and "null" string body is returned
@Get("/null-content")
@ProducesJson
public Publisher<Void> nullBody() {
  return Mono.empty();
}

// 204 No Content status and no body is returned
@Get("/no-content")
@StatusCode(204)
@ProducesJson
public Publisher<Void> noBody() {
  return Mono.empty();
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Armeria server no longer sends chunked encoding header with `}<inlineCode parentName="p">{`304 Not Modified`}</inlineCode>{` status. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4386"
          }}>{`#4386`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`HTTP/2 graceful shutdown timeout is correctly set using `}<a parentName="p" {...{
            "href": "type://ClientFactory#idleTimeoutMillis()"
          }}>{`type://ClientFactory#idleTimeoutMillis()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4381"
          }}>{`#4381`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Unframed `}<a parentName="p" {...{
            "href": "typeplural://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
          }}>{`typeplural://GrpcService`}</a>{` now support `}<inlineCode parentName="p">{`application/x-protobuf`}</inlineCode>{` and `}<inlineCode parentName="p">{`application/x-google-protobuf`}</inlineCode>{`
media types. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4355"
          }}>{`#4355`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4364"
          }}>{`#4364`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use a custom `}<inlineCode parentName="p">{`JsonFormat.Printer`}</inlineCode>{` for `}<a parentName="p" {...{
            "href": "type://ProtobufResponseConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/protobuf/ProtobufResponseConverterFunction.html"
          }}>{`type://ProtobufResponseConverterFunction`}</a>{`.  `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4301"
          }}>{`#4301`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4336"
          }}>{`#4336`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-documentation",
        "aria-label": " documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📃 Documentation`}</h2>
    <ul>
      <li parentName="ul">{`You can now learn how to write a gRPC service and client with Armeria by walking through
`}<a parentName="li" {...{
          "href": "/tutorials/grpc/blog"
        }}>{`the gRPC tutorial`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3987"
        }}>{`#3987`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Special thanks to `}<a parentName="li" {...{
              "href": "https://github.com/freevie"
            }}><strong parentName="a">{`@freevie`}</strong></a>{` who volunteered for this.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DnsResolverGroupBuilder#refreshBackoff(Backoff):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/DnsResolverGroupBuilder.html#refreshBackoff(com.linecorp.armeria.client.retry.Backoff)"
        }}>{`type://DnsResolverGroupBuilder#refreshBackoff(Backoff)`}</a>{` has been deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://DnsResolverGroupBuilder#autoRefreshBackoff(Backoff):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/DnsResolverGroupBuilder.html#autoRefreshBackoff(com.linecorp.armeria.client.retry.Backoff)"
        }}>{`type://DnsResolverGroupBuilder#autoRefreshBackoff(Backoff)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4400"
        }}>{`#4400`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`The method signature of `}<a parentName="li" {...{
          "href": "type://ResponseConverterFunctionProvider#createResponseConverterFunction(Type):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ResponseConverterFunctionProvider.html#createResponseConverterFunction(java.lang.reflect.Type)"
        }}>{`type://ResponseConverterFunctionProvider#createResponseConverterFunction(Type)`}</a>{` has
been changed.`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ResponseConverterFunctionProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ResponseConverterFunctionProvider.html"
            }}>{`type://ResponseConverterFunctionProvider`}</a>{` no longer takes a `}<a parentName="li" {...{
              "href": "type://ResponseConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ResponseConverterFunction.html"
            }}>{`type://ResponseConverterFunction`}</a>{` as
the second parameter. Use `}<a parentName="li" {...{
              "href": "type://DelegatingResponseConverterFunctionProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/DelegatingResponseConverterFunctionProvider.html"
            }}>{`type://DelegatingResponseConverterFunctionProvider`}</a>{` to create a new
`}<a parentName="li" {...{
              "href": "type://ResponseConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ResponseConverterFunction.html"
            }}>{`type://ResponseConverterFunction`}</a>{` based on the delegating `}<a parentName="li" {...{
              "href": "type://ResponseConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ResponseConverterFunction.html"
            }}>{`type://ResponseConverterFunction`}</a>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.13.10 → 5.13.11`}</li>
      <li parentName="ul">{`Brotli4J 1.7.1 → 1.8.0`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.2.10 → 4.2.12`}</li>
      <li parentName="ul">{`gRPC-Java 1.48.0 → 1.49.0`}</li>
      <li parentName="ul">{`Jackson 2.13.3 → 2.13.4`}</li>
      <li parentName="ul">{`Micrometer 1.9.2 → 1.9.3`}</li>
      <li parentName="ul">{`Reactor 3.4.21 → 3.4.22`}</li>
      <li parentName="ul">{`Sangria 3.0.1 → 3.2.0`}</li>
      <li parentName="ul">{`Scala 3.1.3 → 3.2.0`}</li>
      <li parentName="ul">{`Spring Boot 2.7.2 → 2.7.3`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['DasAmpharos', 'anuraaga', 'cnabro', 'devdynam0507', 'dinujoh', 'freevie', 'ghkim3221', 'heowc', 'ikhoon', 'injae-kim', 'jrhee17', 'klurpicolo', 'ks-yim', 'minwoox', 'mscheong01', 'resquivel-squareup', 'tawAsh1', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      